import React, { useContext, useState } from 'react';
import { HeaderTabItem, UpdatingServices } from '@vtblife/layout-config/types';
import cn from 'classnames';

import styles from './dropdown-menu.module.css';
import { DropdownMenuItem } from './dropdown-menu-item/dropdown-menu-item';
import { DropdownMenuButton } from './dropdown-menu-button/dropdown-menu-button';
import { comparePathnameWithTab } from '../utils';
import { HeaderContextValue } from '../header-context';
import { useServiceUpdatesContext } from '../../shell/contexts';
import { sendAnalyticsEvent } from '../../analytics/send-analytics-event';
import { useActiveTab } from '../hooks';

export interface DropdownMenuProps {
    pathname?: string;
    item: HeaderTabItem;
    isMainMenu?: boolean;
    isOpenByDefault: boolean;
    onSubItemClick?: () => void;
    event?: string;
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
    item,
    pathname,
    isMainMenu,
    onSubItemClick,
    isOpenByDefault,
    event,
}) => {
    const { id, displayName, icon, dropdownMenu, showNotification } = item;
    const isActive = comparePathnameWithTab(pathname, item);
    const { isTransparent, isTablet } = useContext(HeaderContextValue);
    const [open, toggleOpen] = useState(isOpenByDefault);
    const activeTab = useActiveTab();

    const freshCount = useServiceUpdatesContext(id as UpdatingServices);

    return (
        <div
            className={cn(styles.dropdownMenu, {
                [styles.dropdownMenuTransparent]: isTransparent,
                [styles.dropdownMenuMain]: isMainMenu,
            })}
        >
            <DropdownMenuButton
                icon={icon}
                isMainMenu={isMainMenu}
                label={displayName}
                isOpen={open}
                onClick={() => {
                    if (event) {
                        sendAnalyticsEvent({
                            event,
                            user_type: activeTab?.displayName,
                            product_type: displayName,
                        });
                    }

                    if (isTablet) {
                        toggleOpen(!open);
                    }
                }}
                showNotification={showNotification || Boolean(freshCount)}
                className={cn(styles.dropdownMenuButton, {
                    [styles.dropdownMenuButtonActive]: isActive,
                })}
            />
            {open && (
                <div className={styles.dropdownMenuContent}>
                    <div className={styles.dropdownMenuContentInner}>
                        {dropdownMenu?.map((item) => (
                            <DropdownMenuItem
                                key={item.id}
                                item={item}
                                pathname={pathname}
                                onClick={() => {
                                    if (event) {
                                        sendAnalyticsEvent({
                                            event,
                                            user_type: activeTab?.displayName,
                                            product_type: item.displayName,
                                        });
                                    }
                                    onSubItemClick?.();
                                }}
                                isMainMenu={isMainMenu}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};
